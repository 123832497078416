
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../service/authentication.service';
import { first } from 'rxjs/operators';
import { ReCaptchaService } from 'angular-recaptcha3';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {
  remember: boolean;
  isLoading: boolean;
  loginForm: UntypedFormGroup;
  submitted = false;
  error = '';
  isError = false;


  constructor(private formBuilder: UntypedFormBuilder,
    private router: Router,
    private translate: TranslateService,
    private recaptchaService: ReCaptchaService,
    private authenticationService: AuthenticationService) {

    translate.use(translate.currentLang || translate.getDefaultLang());

    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }



  }
  ngOnInit(): void {

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      isRemember: false
    });


  }

  switchLanguage(): void {

    if (this.translate.currentLang == 'th') {
      this.translate.use('en');
    } else {
      this.translate.use('th');
    }

  }

  get f() { return this.loginForm.controls; }

  onSubmit() {


    // this.recaptchaService.execute({action: 'login'}).then(token => {
    //   // Backend verification method
    //   console.log(token);

    //   //this.sendTokenToBackend(token);
    // });



    // stop here if form is invalid
    if (this.loginForm.invalid) {
      this.isError = true;
      this.error = "Please enter username or password"
      this.isLoading = false;
      return;
    }
    this.isLoading = true;
    this.submitted = true;
    this.authenticationService.login(this.f.username.value, this.f.password.value, this.f.isRemember.value)
      .pipe(first())
      .subscribe({
        next: res => {
          if(res.role !== 'OFFICER') {
            this.error = "You are not officer.";
            this.isLoading = false;
            return;
          }
          this.router.navigate(['/']);
          this.isError = false;
          this.isLoading = false;
          this.error = "";
        },
        error: error => {
          console.log(error);
          this.error = error;
          this.isLoading = false;
          this.isError = true;
        }
      });
  }
}

