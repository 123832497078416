import {Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'dialog-success',
  templateUrl: 'dialog-success.component.html',
})
export class DialogSuccessComponent {

  title: string;
  constructor(
    private dialogRef: MatDialogRef<DialogSuccessComponent>,
    @Inject(MAT_DIALOG_DATA) data: any) {

       this.title = 'dialog.' + data.title;


    }

}
