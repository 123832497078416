// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  name: 'dev',
  appVersion: '1.0.0',
  production: false,
  useMock: false,
  apiUrl: 'https://api-dev.domi-tech.co',
  recaptchaSitekey: '6LfvsqMcAAAAAHK4ZpdJT4O-DH5DpsuVY7QDV0i2',
};
