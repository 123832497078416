import { UserMock } from './../model/user-mock.model';
import { User } from './../model/user.model';
import { StatusLetter } from './../share/const';
import { Area } from './../model/area.model';
import { LetterToPrison } from '../model/letter-to-prison.model';
import { LetterToHome } from '../model/letter-to-home.model';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, of, throwError, zip } from 'rxjs';
import { delay, mergeMap, materialize, dematerialize } from 'rxjs/operators';
import { Letter } from '../model/letter.model';
import { CookieService } from '../service/cookie.service';

const usersKey = 'angular-9-jwt-refresh-token-users';

const today: Date = new Date();
const yesterday: Date = new Date();
yesterday.setDate(today.getDate() - 1);
const fewday: Date = new Date();
fewday.setDate(today.getDate() - 2);

const users: UserMock[] = [{ id: 1,  first_name: 'Admin', last_name: 'Admin', email: null, phone: '0880630107', role: 'OFFICER', gender: 'MALE', update_dt: today, create_dt: today, prison_id: 1, password: '1234'}];

const area: Area[] = [
  { area_name: 'แดน 1', id: 1, prison_id: 1, create_dt: today, update_dt: today },
  { area_name: 'แดน 2', id: 2, prison_id: 1,  create_dt: today, update_dt: today },
  { area_name: 'แดน 3', id: 3, prison_id: 1,  create_dt: today, update_dt: today }
];
const letter: Letter[] = [
  {
    id: 1,
    no: '21-00001',
    sender: 'นายอุดม รักสง่า',
    receiver: 'นายรักษา รักสง่า',
    status: StatusLetter.printed,
    area: 'แดน 1',
    balance: 10,
    area_id: 1,
    imgUrl: 'https://img.rawpixel.com/s3fs-private/rawpixel_images/website_content/pf-s2-s54-win-22_1_1.jpg?w=800&dpr=1&fit=default&crop=default&q=65&vib=3&con=3&usm=15&bg=F4F4F3&ixlib=js-2.2.1&s=16c399be22c4839a0fc09ebcc08765fa',
    create_dt: today,
    update_dt: today
  },
  {
    id: 2,
    no: '21-00002',
    sender: 'นายอุดม รักสง่า',
    receiver: 'นายห้าเหลี่ยม จัตรัต',
    balance: 10,
    status: StatusLetter.nonprint,
    area: 'แดน 2',
    area_id: 2,
    imgUrl: 'https://img.rawpixel.com/s3fs-private/rawpixel_images/website_content/pf-s2-s54-win-22_1_1.jpg?w=800&dpr=1&fit=default&crop=default&q=65&vib=3&con=3&usm=15&bg=F4F4F3&ixlib=js-2.2.1&s=16c399be22c4839a0fc09ebcc08765fa',
    create_dt: fewday,
    update_dt: today
  },
  {
    id: 3,
    no: '21-00001',
    sender: 'นายอุดม รักสง่า',
    receiver: 'นายห้าเหลี่ยม จัตรัต',
    balance: 10,
    status: StatusLetter.nonprint,
    area: 'แดน 3',
    area_id: 3,
    imgUrl: 'https://img.rawpixel.com/s3fs-private/rawpixel_images/website_content/pf-s2-s54-win-22_1_1.jpg?w=800&dpr=1&fit=default&crop=default&q=65&vib=3&con=3&usm=15&bg=F4F4F3&ixlib=js-2.2.1&s=16c399be22c4839a0fc09ebcc08765fa',
    create_dt: yesterday,
    update_dt: today
  }
];
const letterToPrison: LetterToPrison[] = [
  {
    id: 1,
    no: '21-00001',
    sender: 'นายอุดม รักสง่า',
    receiver: 'นายรักษา รักสง่า',
    area: 'แดน 1',
    area_id: 1,
    prisoner_no: '0001',
    status: StatusLetter.printed,
    imgUrl: 'https://pbs.twimg.com/media/E4ABZfzVIAIT1st.jpg',
    create_dt: today,
    update_dt: fewday
  },
  {
    id: 2,
    no: '21-00002',
    sender: 'นายสี่เหลียม จัตรัต',
    receiver: 'นายห้าเหลี่ยม จัตรัต',
    area: 'แดน 2',
    area_id: 2,
    prisoner_no: '0002',
    status: StatusLetter.printed,
    imgUrl: 'https://images.unsplash.com/photo-1490730141103-6cac27aaab94?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1740&q=80',
    create_dt: today,
    update_dt: today
  },
  {
    id: 3,
    no: '21-00003',
    sender: 'นายสามเหลี่ยม วงกลม',
    receiver: 'นายหนึ่งเหลี่ยม วงกลม',
    area: 'แดน 1',
    area_id: 1,
    prisoner_no: '0002',
    status: StatusLetter.nonprint,
    imgUrl: 'https://images.unsplash.com/photo-1542353436-312f0e1f67ff?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1242&q=80',
    create_dt: today,
    update_dt: today
  },
  {
    id: 4,
    no: '21-00004',
    sender: 'นายสามเหลี่ยม วงกลม',
    receiver: 'นายหนึ่งเหลี่ยม วงกลม',
    area: 'แดน 3',
    area_id: 3,
    prisoner_no: '0004',
    status: StatusLetter.nonprint,
    imgUrl: 'https://images.unsplash.com/photo-1536782376847-5c9d14d97cc0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1752&q=80',
    create_dt: yesterday,
    update_dt: yesterday
  }
];

const letterToHome: LetterToHome[] = [
  {
    id: 1,
    no: '21-00001',
    sender: 'นายอุดม รักสง่า',
    receiver: 'นายรักษา รักสง่า',
    area: 'แดน 1',
    area_id: 1,
    prisoner_no: '0001',
    status: StatusLetter.printed,
    imgUrl: 'https://pbs.twimg.com/media/E4ABZfzVIAIT1st.jpg',
    create_dt: today,
    update_dt: fewday,
    letter_id: 1,
  },
  {
    id: 2,
    no: '21-00002',
    sender: 'นายสี่เหลียม จัตรัต',
    receiver: 'นายห้าเหลี่ยม จัตรัต',
    area: 'แดน 2',
    area_id: 2,
    prisoner_no: '0002',
    status: StatusLetter.printed,
    imgUrl: 'https://images.unsplash.com/photo-1490730141103-6cac27aaab94?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1740&q=80',
    create_dt: today,
    update_dt: today,
    letter_id: 1,
  },
  {
    id: 3,
    no: '21-00003',
    sender: 'นายสามเหลี่ยม วงกลม',
    receiver: 'นายหนึ่งเหลี่ยม วงกลม',
    area: 'แดน 1',
    area_id: 1,
    prisoner_no: '0002',
    status: StatusLetter.nonprint,
    imgUrl: 'https://images.unsplash.com/photo-1542353436-312f0e1f67ff?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1242&q=80',
    create_dt: today,
    update_dt: today,
    letter_id: 1,
  },
  {
    id: 4,
    no: '21-00004',
    sender: 'นายสามเหลี่ยม วงกลม',
    receiver: 'นายหนึ่งเหลี่ยม วงกลม',
    area: 'แดน 3',
    area_id: 3,
    prisoner_no: '0004',
    status: StatusLetter.nonprint,
    imgUrl: 'https://images.unsplash.com/photo-1536782376847-5c9d14d97cc0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1752&q=80',
    create_dt: yesterday,
    update_dt: yesterday,
    letter_id: 1,
  }
];

const scanningLetter = {
    id: 1,
    sender: 'นายอุดม รักสง่า',
    receiver: 'นายรักษา รักสง่า',
    area: 'แดน 1',
    status: 'draft',
    timestamp: 1633536048000,
    imgUrl: 'https://pbs.twimg.com/media/E4ABZfzVIAIT1st.jpg'
  };

@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {
  cookieservice: CookieService;

  constructor(private cookieService: CookieService) {
    this.cookieService = cookieService;
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { url, method, headers, body } = request;

    // wrap in delayed observable to simulate server api call
    return of(null)
      .pipe(mergeMap(handleRoute))
      .pipe(materialize())
      .pipe(delay(500))
      .pipe(dematerialize());

    function handleRoute() {
      switch (true) {
        case url.endsWith('/authenticate/login') && method === 'POST':
          return login();
        case url.endsWith('/authenticate/logout') && method === 'POST':
          return logout();
        case url.endsWith('/authenticate/refresh-token') && method === 'POST':
          return refreshToken();
        case url.endsWith('/area') && method === 'GET':
          return getArea();
        case url.endsWith('/v1/letter/lp') && method === 'GET':
          return getLetterToPrison();
        case url.endsWith('/v1/letter/lh') && method === 'GET':
          return getLetterToHome();
        case url.endsWith('/v1/letter/lh-template') && method === 'GET':
          return getLetter();
        case url.endsWith('/v1/letter/scan') && method === 'POST':
          return scanImage();
        case url.endsWith('/v1/user/forget/password') && method === 'POST':
          return forgetPassword();
        default:
          // pass through any requests not handled above
          return next.handle(request);
      }
    }

    // route functions

    function login() {
      const { phone, password } = body;
      const user: User = users.find(x => x.phone === phone &&  x.password === password);

      if (!user) { return error('Username or password is incorrect'); }
      generateRefreshToken();
      // generateJwtToken()

      return ok(user);
    }

    function refreshToken() {
      const token = getRefreshToken();
      if (!token) { return unauthorized(); }

      const user = users[0];
      if (!user) { return unauthorized(); }

      // replace old refresh token with a new one and save
      generateRefreshToken();
      return ok(user);
    }

    function revokeToken() {
      if (!isLoggedIn()) { return unauthorized(); }
      document.cookie = ``;
      return ok();
    }

    function logout() {
      revokeToken();
      return ok();
    }

    function generateJwtToken() {
      // create token that expires in 30 minutes
      const tokenPayload = { exp: Math.round(new Date(Date.now() + (30 * 60 * 1000)).getTime() / 1000) };
      // add token cookie that expires in 1 hours
      const expires = new Date(Date.now() + (60 * 60 * 1000)).toUTCString();
      document.cookie = `access-token=${'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjM2MjM5MDIyfQ.CzBE30_j099u9ATupU1hGEw7qYpWLieT2CCMJKPgx-M'}`;
    }

    function generateRefreshToken() {
      const token = new Date().getTime().toString();

      // add token cookie that expires in 1 hours
      const expires = new Date(Date.now() + (60 * 60 * 1000)).toUTCString();
      document.cookie = `access-token=${token}; expires=${expires}; path=/`;

      return token;
    }

    function getRefreshToken() {
      // get refresh token from cookie
      return (document.cookie.split(';').find(x => x.includes('refresh-token')) || '=').split('=')[1];
    }

    // calling the function
    function forgetPassword() {
      return ok({success: 'otp'});
    }
    function scanImage() {
      if (!isLoggedIn()) { return unauthorized(); }
      return ok(scanningLetter);
    }

    function getArea() {
      if (!isLoggedIn()) { return unauthorized(); }
      return ok(area);
    }
    function getLetterToPrison() {
      if (!isLoggedIn()) { return unauthorized(); }
      return ok(letterToPrison);
    }
    function getLetter() {
      if (!isLoggedIn()) { return unauthorized(); }
      return ok(letter);
    }

    function getLetterToHome() {
      if (!isLoggedIn()) { return unauthorized(); }
      return ok(letterToHome);
    }


    // helper functions

    // tslint:disable-next-line:no-shadowed-variable
    function ok(body?) {
      return of(new HttpResponse({ status: 200, body }));
    }

    function error(message) {
      return throwError({ error: { message } });
    }

    function unauthorized() {
      return throwError({ status: 401, error: { message: 'Unauthorised' } });
    }

    function isLoggedIn() {
      // check if jwt token is in auth header
      const authHeader = headers.get('Authorization');
      if (!authHeader.startsWith('Bearer fake-jwt-token')) { return false; }

      // check if token is expired
      const jwtToken = JSON.parse(atob(authHeader.split('.')[1]));
      const tokenExpired = Date.now() > (jwtToken.exp * 1000);
      if (tokenExpired) { return false; }

      return true;
  }
  }
}

export const fakeBackendProvider = {
  // use fake backend in place of Http service for backend-less development
  provide: HTTP_INTERCEPTORS,
  useClass: FakeBackendInterceptor,
  multi: true
};
