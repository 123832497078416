import { CookieService } from './cookie.service';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { User } from '../model/user.model';
import jwt_decode from 'jwt-decode';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  constructor(private route: Router, private http: HttpClient, private cookieService: CookieService) {

    this.currentUserSubject = new BehaviorSubject<User>(null);
    this.currentUser = this.currentUserSubject.asObservable();

  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  rememberMe = false;

  // helper methods

  private refreshTokenTimeout;



  login(username: string, password: string, isRemember: boolean): Observable<User> {

    const data = {
      phone: username,
      password: password,
      is_remember: isRemember
    };

    return this.http.post<any>(`${environment.apiUrl}/authenticate/login`, data, {withCredentials: true, responseType: 'json', observe: 'response' })
    .pipe(map(res => {

      const user: User = res.body.data as User;
        this.currentUserSubject.next(user);
        localStorage.setItem('isLogged', 'true');
        localStorage.setItem('isRemember', isRemember ? 'true' : 'false');
        this.startRefreshTokenTimer(res);

      return user;

    }));

  }

  resetcredentials() {
    // clear all localstorages
    localStorage.removeItem('isRememberCurrentUser');
    localStorage.removeItem('currentUser');
    sessionStorage.removeItem('currentUser');
    this.rememberMe = false;
    this.currentUserSubject.next(null);
  }

  logout(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/authenticate/logout`, {}, { withCredentials: true }).pipe(
      finalize(() => {
        this.currentUserSubject.next(null);
        localStorage.removeItem('isLogged');
        localStorage.removeItem('isRemember');
        this.stopRefreshTokenTimer();
      })
      );
  }

  forget(tel: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/authentivate/forget/password`, { telephone: tel })
      .pipe(map(forget => {
        return forget;
      }));
  }

  refreshToken(): Observable<User> {

    // return from(fetch(`${environment.apiUrl}/authenticate/refresh-token`, {
    //   method: 'POST',
    //   credentials: 'include',
    // }).then(res => res.json()).then(res => res as User))

    if (localStorage.getItem('isLogged') === 'true' || localStorage.getItem('isRemember') === 'true') {
      return this.http.post<any>(`${environment.apiUrl}/authenticate/refresh-token`, {},
      { withCredentials: true, responseType: 'json', observe: 'response' })
      .pipe(map((res) => {
        const user: User = res.body.data as User;
        this.currentUserSubject.next(user);
        this.startRefreshTokenTimer(res);
        return user;
      }));
    }
    return of();
  }


  private startRefreshTokenTimer(res: HttpResponse<any>) {
    // parse json object from base64 encoded jwt token

    const token = res.headers.get('access-token');

    const jwtDecode: any = jwt_decode(token);

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtDecode.exp * 1000);

    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }
  private clearRefreshCookie() {
    this.cookieService.deleteCookie('refresh-token');
  }
}
