import { content } from 'html2canvas/dist/types/css/property-descriptors/content';
import {Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'dialog-error',
  templateUrl: 'dialog-error.component.html',
})
export class DialogErrorComponent {

  title:string;
  content:string;

  constructor(
    private dialogRef: MatDialogRef<DialogErrorComponent>,
    @Inject(MAT_DIALOG_DATA) data: any){
       this.title = 'dialog.' + data.title;
       this.content = data.content
    }

}
