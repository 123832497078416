import { Prison } from './../../model/prison.model';

import { NavigationEnd, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { AuthenticationService } from '../../service/authentication.service';
import { User } from '../../model/user.model';
import { interval } from 'rxjs';
import { map } from 'rxjs/operators';
import { DomimailService } from '../../service/domimail.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnInit {

  breadcrumb:string
  public sidebarMinimized = false;
  user: User;
  date: Date;
  prison: Prison;

  constructor(private translate: TranslateService,
    private router: Router,
    private authenticationService :AuthenticationService,
    private domimailSvc: DomimailService,
    ) {

    translate.use(translate.currentLang||translate.getDefaultLang());


    this.date = new Date();
    interval(60000).pipe(map(() => new Date())).subscribe(e=>{
      this.date = e
    });


    //Breadcrumb handle
    this.router.events.subscribe((evt) => {

      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      if(evt.urlAfterRedirects == '/dashboard'){
        this.breadcrumb = "";
        return;
      }

      this.breadcrumb = 'menu.'+ evt.urlAfterRedirects.split('/')[1]

    });

  }
  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(userResponse => {
      this.user = userResponse
      this.domimailSvc.getPrisonById(this.user.prison_id).subscribe(prisonResponse => {
        this.prison = prisonResponse;
      })
    });
  }

  logout():void{
    this.authenticationService.logout().subscribe({
      next: res => {
        this.router.navigate(['/login'])
      },
      error: err => {

      }
    })

  }


  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }
  toggleMinimizeIcon() {

    this.sidebarMinimized = !this.sidebarMinimized;
  }

  switchLanguage(): void {

    if(this.translate.currentLang == 'th'){
      this.translate.use('en');
      localStorage.setItem("language","en");
    }else{
      this.translate.use('th');
      localStorage.setItem("language","th");
    }
  }
}

