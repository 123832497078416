import { StatusLetter } from '../share/const';

export class UpdateLetter {
  id: number;
  status: StatusLetter;
}
export class UpdateLetterMany {
  id: number[];
  status: StatusLetter;
}
