import { MatPaginatorIntl } from '@angular/material/paginator';
import {Injectable} from '@angular/core';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
  constructor() {
    super();

    this.getAndInitTranslations();
  }

  getAndInitTranslations() {

      this.itemsPerPageLabel = 'จำนวนต่อหน้า';
      this.nextPageLabel = 'ถัดไป';
      this.previousPageLabel = 'ย้อนกลับ';
      this.changes.next();

  }

 getRangeLabel = (page: number, pageSize: number, length: number) =>  {
    if (length === 0 || pageSize === 0) {
      return `หน้า 0 จากทั้งหมด ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return `ลำดับ ${startIndex + 1} - ${endIndex} จาก ${length} ฉบับ`;
  }
}
