
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthenticationService } from '../service/authentication.service';


@Injectable()
export class AuthGuard implements CanActivate {
  private version: string =  environment.appVersion;

  constructor(private router: Router, private authenticationService: AuthenticationService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const user = this.authenticationService.currentUserValue;

    if (user && user.role === 'OFFICER') {
        return true;
    }
    this.router.navigate(['/login'])
    return false;


  }
}
