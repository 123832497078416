import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../service/authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(request).pipe(catchError(err => {

        if ([401, 403].includes(err.status) && this.authenticationService.currentUserValue) {
          // auto logout if 401 or 403 response returned from api
          this.authenticationService.logout();
        }

      //   const flag = request.url.includes('/authenticate/refresh-token') || request.url.includes('/authenticate/logout')


      //     if ([401].includes(err.status) && !flag) {

      //       this.authenticationService.refreshToken().subscribe(
      //         {
      //           next: () => {
      //             next.handle(request);
      //           },
      //         }
      //       )

      //     } else if(request.url.includes('/authenticate/logout')) {

      //     } else {
      //       this.authenticationService.logout();
      //     }

          const error = (err && err.error && err.error.message) || err.statusText;
          return throwError(error);
      }))
  }

}
