import { environment } from "./../environments/environment";
import { ForgetComponent } from "./views/forget/forget.component";

// Import modules
import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import {
  LocationStrategy,
  HashLocationStrategy,
  CommonModule,
  DatePipe,
} from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TabsModule } from "ngx-bootstrap/tabs";
import { AppComponent } from "./app.component";
import { MatButtonModule } from "@angular/material/button";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { MatDialogModule } from "@angular/material/dialog";
import { MatInputModule } from "@angular/material/input";
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS } from "@angular/material/core";
import { MatFormFieldModule } from "@angular/material/form-field";

// Import config
import * as config from "./share/config";
// Import containers
import { DefaultLayoutComponent } from "./containers";

// Import component
import { P404Component } from "./views/error/404.component";
import { P500Component } from "./views/error/500.component";
import { LoginComponent } from "./views/login/login.component";
import { DialogPrintComponent } from "./views/dialog/dialog-print/dialog-print.component";

import {
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from "@coreui/angular";

// Import routing module
import { AppRoutingModule } from "./app.routing";

// Import helper
import { AuthGuard } from "./helper/auth.guard";
import { TokenInterceptor } from "./helper/jwt-interceptor";
import { ErrorInterceptor } from "./helper/error-interceptor";
import { fakeBackendProvider } from "./helper/fake-backend";
import { ReCaptchaModule } from "angular-recaptcha3";
import { TranslateModule } from "@ngx-translate/core";
import { DialogImageComponent } from "./views/dialog/dialog-img/dialog-img.component";
import { appInitializer } from "./helper/app-initialize";
import { AuthenticationService } from "./service/authentication.service";
import { DialogSuccessComponent } from "./views/dialog/dialog-success/dialog-success.component";
import { DialogScanImageComponent } from "./views/dialog/dialog-scan-img/dialog-scan-img.component";
import { DialogErrorComponent } from "./views/dialog/dialog-error/dialog-error.component";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { CustomMatPaginatorIntl } from "./modules/custom-paginator.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ScrollingModule } from "@angular/cdk/scrolling";

import {
  NgbCalendar,
  NgbDatepickerI18n,
  NgbCalendarBuddhist,
  NgbDateParserFormatter,
  NgbDateAdapter,
} from "@ng-bootstrap/ng-bootstrap";
import {
  NgbDatepickerI18nBuddhist,
  CustomAdapter,
  CustomDateParserFormatter,
} from "./modules/custom-datepicker.module";
import { DialogOfficerMessageComponent } from "./views/dialog/dialog-officer-message/dialog-officer-message.component";

const fakeServiceProviders = environment.useMock ? [fakeBackendProvider] : [];
@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    MatFormFieldModule,
    MatDialogModule,
    MatInputModule,
    ScrollingModule,
    FormsModule,
    MatButtonModule,
    ButtonsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ReCaptchaModule.forRoot(config.RECAPTCHA_OPTION),
    TranslateModule.forRoot(config.TRANSLATE_OPTION),
    NgbModule,
  ],
  declarations: [
    AppComponent,
    DefaultLayoutComponent,
    P404Component,
    P500Component,
    LoginComponent,
    ForgetComponent,
    DialogPrintComponent,
    DialogImageComponent,
    DialogSuccessComponent,
    DialogScanImageComponent,
    DialogErrorComponent,
    DialogOfficerMessageComponent,
  ],
  providers: [
    DatePipe,
    AuthGuard,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    ,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthenticationService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: "th-TH",
    },
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl,
    },
    { provide: NgbCalendar, useClass: NgbCalendarBuddhist },
    { provide: NgbDatepickerI18n, useClass: NgbDatepickerI18nBuddhist },
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },

    // provider used to create fake backend
    ...fakeServiceProviders,
  ],
  exports: [],

  bootstrap: [AppComponent],
})
export class AppModule {}
