
import { environment } from '../../environments/environment';
// import { environment } from '@environments/environment';
import { TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';

//6Lc3H6McAAAAAJyEEzJeVB9lSfdF675jYPQ_l99x
export const RECAPTCHA_OPTION = {
  invisible: {
    sitekey: environment.recaptchaSitekey,
  },
  normal: {
    sitekey: environment.recaptchaSitekey,
  },
  language: 'en'
}

export const TRANSLATE_OPTION = {
  defaultLanguage: 'th',
  useDefaultLang: true,
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient]
  }
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

