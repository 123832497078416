import {Injectable, Injector} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../service/authentication.service';
import { environment } from '../../environments/environment';


/**
 * TokenInterceptor
 * @see https://angular.io/guide/http#intercepting-all-requests-or-responses
 */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(private authenticationService: AuthenticationService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      // add auth header with jwt if user is logged in and request is to the api url
      const user = this.authenticationService.currentUserValue;
      const isLoggedIn = user
      const isApiUrl = request.url.startsWith(environment.apiUrl);
      if (isLoggedIn && isApiUrl) {
          request = request.clone({
              // setHeaders: {
              //   Authorization: `Bearer ${user.accessToken}`,
              //   "Access-Control-Allow-Origin": 'http://localhost:4200' ,
              //   "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
              // }
          });
      }

      return next.handle(request);
  }
}
