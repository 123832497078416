
import {Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import jsPDF from 'jspdf';
import { DomimailService } from '../../../service/domimail.service';
import { UpdateLetterMany } from '../../../model/update-letter.model';
import { StatusLetter } from '../../../share/const';

@Component({
  selector: 'dialog-print',
  templateUrl: 'dialog-print.component.html',
})
export class DialogPrintComponent {

  letters: any;
  title: string;
  icon: string;
  value: number;
  isLoading = false;
  isPrint = false;
  letterType: string;

  constructor(
    private domimail: DomimailService,
    private dialogRef: MatDialogRef<DialogPrintComponent>,
    @Inject(MAT_DIALOG_DATA) data: any) {
       this.letters = data.content;
       this.title = 'dialog.' + data.title;
       this.icon = data.icon ;
       this.value = this.letters.length;
       this.letterType = data.letterType;
    }

    onPrintClick() {
      if (this.isLoading === true || this.isPrint === true) { return; }
      this.isLoading = true;

      if (this.letterType === 'LHT') {
        const updateData = new UpdateLetterMany();
        updateData.id = [];
        for (const element of this.letters) {
          updateData.id.push(element.id);
        }
        updateData.status = StatusLetter.printed;
        this.domimail.updateLHTemplateMany(updateData).subscribe({
          next: res => {
            this.isPrint = true;
            this.createPDF();
            this.isLoading = false;
            this.dialogRef.close(true);
          },
          error: err => {
            this.isLoading = false;
            this.dialogRef.close(false);
          }
        });

      } else if (this.letterType === 'LH') {
        this.createPDF();
      } else if (this.letterType === 'LP') {
        const updateData = new UpdateLetterMany();
        updateData.id = [];
        for (const e of this.letters) {
          updateData.id.push(e.id);
        }
        updateData.status = StatusLetter.printed;
        this.domimail.updateLPMany(updateData).subscribe({
          next: res => {
            this.isPrint = true;
            this.createPDF();
            this.isLoading = false;
            this.dialogRef.close(true);
          },
          error: err => {
            this.dialogRef.close(false);
          }
        });
      } else {
        return;
      }
    }
    createPDF = () => {
      try {
        if (!this.letters || this.letters.length === 0) { return; }
        const filename = 'letter_' + new Date().toLocaleDateString() + '_all' + '.pdf';
        const margin = 16;
        const position = 16;
        const width = 210 - (2 * margin);
        const height = 297 - (2 * position);
        const PDF = new jsPDF('p', 'mm', 'a4', true);
        PDF.setFontSize(12);
        PDF.setTextColor(105, 105, 105);

        if (this.letterType === 'LHT') {
          for (let i = 0; i < this.letters.length; i++) {
           try {
            const image = new Image();
            image.src = this.letters[i].imgUrl ;
            for (let j = 0; j < 10; j++) {

              PDF.text( 'No ' + (j + 1) , 210 - margin * 2, margin - 2);
              PDF.addImage(image, 'PNG', margin, position, width, height);
              if (!(i === this.letters.length - 1 && j === 9)) {
                PDF.addPage();
              }
            }
           } catch (error) {
              continue;
           }
          }
        } else {
          for (let i = 0; i < this.letters.length; i++) {
           try {
            const image = new Image();
            image.src = this.letters[i].imgUrl ;
            PDF.addImage(image, 'PNG', margin, position, width, height);
            if (i < this.letters.length - 1  ) { PDF.addPage(); }
           } catch (error) {
            continue;
           }
          }
        }

        PDF.autoPrint({variant: 'non-conform'});
        PDF.save(filename);
        const blob = PDF.output('blob');
        window.open(URL.createObjectURL(blob));

      } catch (error) {
        this.isLoading = false;
        this.dialogRef.close(true);
      }
    }
}
