import { OfficerMessageDto } from './../../../model/officer-message.model';

import { DomimailService } from '../../../service/domimail.service';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'dialog-officer-message',
  templateUrl: 'dialog-officer-message.component.html',
})
export class DialogOfficerMessageComponent {
  isLoading = false;
  letter: any;
  letterType: string;
  title: string;
  userName: string;
  hasImage: boolean = false
  officerDto: OfficerMessageDto;
  message: string = "";
  imgUrl: string;
  showErr: boolean = false;
  requiredFileType = 'image/png, image/jpeg';
  constructor(
    private domimail: DomimailService,
    private dialogRef: MatDialogRef<DialogOfficerMessageComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.letter = data.content;
    this.title = 'dialog.' + data.title;
    this.userName = this.letter.sender
    this.officerDto = {
      lp_id: this.letter.id,
      message: this.message
    }
  }
  onConfirm(){
    this.isLoading = true;
    this.officerDto = { ...this.officerDto, message: this.message }

    this.domimail.messageToUser(this.officerDto).subscribe({
      next: () => {
        this.isLoading = false;
        this.dialogRef.close({isSuccess: true});
      },
      error: () => {
        this.isLoading = false;
        this.dialogRef.close({isSuccess: false});
      }
    })
  }
  async addImage(event: any){
    this.isLoading = true;
    let file: File = event.target.files[0];

    async function onLoadDataUrl(file: File) {
      const reader = new FileReader();
      return new Promise((resolve) => {
        reader.onload = (ev) => {
          resolve(ev.target.result.toString());
        };
        reader.readAsDataURL(file);
      });
    }
    onLoadDataUrl(file).then(url => {
      console.log();

      if(file.type.match('image\/*/')!=null && (file.size /1024 / 1024) < 6){
        this.imgUrl = url as string
        this.hasImage = true;
        this.showErr = false;
        this.officerDto = { ...this.officerDto, file: file }
      }else{
        this.showErr = true;
      }

      this.isLoading = false

    }).catch(() => {
      this.isLoading = false
      this.hasImage = false;
    })

  }
  deleteImage(){
    this.hasImage = false;
    this.officerDto = {
      lp_id: this.letter.id,
      message: this.message,
    }
  }
}
