import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { AuthenticationService } from './service/authentication.service';
import { User } from './model/user.model';

@Component({

  selector: 'body',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
  currentUser: User;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private authenticationService: AuthenticationService
  ) {

    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);

    if (localStorage.getItem('language')) {
      translate.setDefaultLang(localStorage.getItem('language'));
      translate.use(localStorage.getItem('language'));
    } else {
      translate.setDefaultLang('th');
      translate.use('th');
      localStorage.setItem("language", "th");
    }
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {

      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

}
