import { UpdateLetter } from './../../../model/update-letter.model';
import { DomimailService } from './../../../service/domimail.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import jsPDF from 'jspdf';
import { StatusLetter } from '../../../share/const';

@Component({
  selector: 'dialog-img',
  templateUrl: 'dialog-img.component.html',
})
export class DialogImageComponent {
  isLoading = false;
  letter: any;
  image;
  imgUrl: string;
  isPrint = false;
  letterType: string;

  constructor(
    private domimail: DomimailService,
    private dialogRef: MatDialogRef<DialogImageComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.letter = data.content;
    this.imgUrl = this.letter.imgUrl;
    this.letterType = data.letterType;
  }
  onPrintClick() {
    if (this.isLoading === true || this.isPrint === true) { return; }
    this.isLoading = true;
    if (this.letterType === 'LHT') {
      const updateData = new UpdateLetter();
      updateData.id = this.letter.id;
      updateData.status = StatusLetter.printed;
      this.domimail.updateLHTemplate(updateData).subscribe({
        next: res => {
          this.isPrint = true;
          this.createPDF();
          this.isLoading = false;
          this.dialogRef.close(true);
        },
        error: err => {
          this.isLoading = false;
          this.dialogRef.close(false);
        }
      });

    } else if (this.letterType === 'LH') {
      this.createPDF();
    } else if (this.letterType === 'LP') {
      this.domimail.updateLP({ id: this.letter.id, status: StatusLetter.printed}).subscribe({
        next: res => {
          this.createPDF();
          this.isLoading = false;
          this.dialogRef.close(true);
        },
        error: err => {
          this.dialogRef.close(false);
        }
      });
    } else {
      return;
    }
  }
  createPDF = () => {
   try {
    const filename = 'letter_' + new Date().toLocaleDateString() + '.pdf';
    const margin = 16;
    const position = 16;
    const width = 210 - 2 * margin;
    const height = 297 - 2 * position;
    const image = new Image();
    image.src = this.letter.imgUrl;
    const PDF = new jsPDF('p', 'mm', 'a4', true);
    if (this.letterType === 'LHT') {
      for (let i = 0; i < 10; i++) {
        PDF.addImage(image, 'PNG', margin, position, width, height);
        PDF.setPage(i + 1);
        PDF.setFontSize(12);
        PDF.setTextColor(105, 105, 105);
        PDF.text( 'No ' + (i + 1) , 210 - margin * 2, margin - 2);
        if (i < 9) { PDF.addPage(); }
      }
    } else {
      image.src = this.letter.imgUrl ;
      PDF.addImage(image, 'PNG', margin, position, width, height);
    }

    PDF.autoPrint({ variant: 'non-conform' });
    PDF.save(filename);
    const blob = PDF.output('blob');
    window.open(URL.createObjectURL(blob));

   } catch (error) {
    this.isLoading = false;
    this.dialogRef.close(true);
   }
  }
}
