import { TotalLetter } from './../model/home.model';

import { ViewLetter } from './../model/view-letter.model';
import { ScanLetter } from './../model/scan-letter.model';
import { UpdateLetter, UpdateLetterMany } from './../model/update-letter.model';

import { Letter } from '../model/letter.model';
import { Area } from './../model/area.model';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { LetterToHome } from '../model/letter-to-home.model';
import { LetterToPrison } from '../model/letter-to-prison.model';
import { FilterParam, FilterParamsHome } from '../model/filterParam.model';
import { Prison } from '../model/prison.model';
import { OfficerMessage, OfficerMessageDto } from '../model/officer-message.model';

@Injectable({ providedIn: 'root' })
export class DomimailService {
  private baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}

  getArea(prison_id: number): Observable<Area[]> {
    return this.http
      .get<any>(`${this.baseUrl}/area/get-by-prison/${prison_id}`, {
        withCredentials: true,
        responseType: 'json',
      })
      .pipe(
        map((res) => {
          return res.data as Area[];
        })
      );
  }
  getPrisonById(prison_id: number): Observable<Prison> {
    return this.http.get<any>(`${this.baseUrl}/prison/${prison_id}`, { withCredentials: true, responseType: 'json' })
      .pipe(map(res => {
        return res.data as Prison;
      }
      ));
  }

  getLetterToPrison(filterParam: FilterParam): Observable<ViewLetter> {
    return this.http
      .get<any>(`${this.baseUrl}/letter/lp`, {
        params: {
          ...filterParam,
        },
        withCredentials: true,
        responseType: 'json',
      })
      .pipe(
        map((res) => {
          return res as ViewLetter;
        })
      );
  }

  getLetter(filterParam: FilterParam): Observable<ViewLetter> {
    return this.http
      .get<any>(`${this.baseUrl}/letter/lh-template`, {
        params: {
          ...filterParam,
        },
        withCredentials: true,
        responseType: 'json',
      })
      .pipe(
        map((res) => {
          return res as ViewLetter;
        })
      );
  }

  getLetterToHome(filterParam: FilterParam): Observable<ViewLetter> {
    return this.http
      .get<any>(`${this.baseUrl}/letter/lh`, {
        params: {
          ...filterParam,
        },
        withCredentials: true,
        responseType: 'json',
      })
      .pipe(
        map((res) => {
          return res as ViewLetter;
        })
      );
  }
  getTotalLetter(filterParam: FilterParamsHome): Observable<TotalLetter> {
    return this.http
      .get<any>(`${this.baseUrl}/letter/total-letter`, {
        params: {
          ...filterParam,
        },
        withCredentials: true,
        responseType: 'json',
      })
      .pipe(
        map((res) => {
          return res.data as TotalLetter;
        })
      );
  }


  updateLHTemplate(data: UpdateLetter): Observable<void> {
    return this.http.patch<any>(
      `${this.baseUrl}/letter/update/lh-template/${data.id}`,
      data,
      { withCredentials: true }
    );
  }
  updateLHTemplateMany(data: UpdateLetterMany): Observable<void> {
    return this.http.patch<any>(
      `${this.baseUrl}/letter/update/many/lh-template`,
      data,
      { withCredentials: true }
    );
  }

  updateLH(data: UpdateLetter): Observable<void> {
    return this.http.patch<any>(
      `${this.baseUrl}/letter/update/lh${data.id}`,
      data,
      { withCredentials: true }
    );
  }

  updateLHMany(data: UpdateLetterMany): Observable<void> {
    return this.http.patch<any>(
      `${this.baseUrl}/letter/update/many/lh`,
      data,
      { withCredentials: true }
    );
  }

  updateLP(data: UpdateLetter): Observable<void> {
    return this.http.patch<any>(
      `${this.baseUrl}/letter/update/lp/${data.id}`,
      data,
      { withCredentials: true }
    );
  }
  updateLPMany(data: UpdateLetterMany): Observable<void> {
    return this.http.patch<any>(
      `${this.baseUrl}/letter/update/many/lp`,
      data,
      { withCredentials: true }
    );
  }

  getLetterTemplateByNo(letter_no: string): Observable<Letter> {
    return this.http
      .get<any>(`${this.baseUrl}/letter/lh-template/${letter_no}`, {
        withCredentials: true,
      })
      .pipe(
        map((res) => {
          return res.data as Letter;
        })
      );
  }

  getLetterTemplateByNoMany(letter_no: string[]): Observable<Letter[]> {
    return this.http.post<any>(`${this.baseUrl}/letter/lh-template/many`, {letter_no: letter_no}, {
        withCredentials: true,
      })
      .pipe(
        map((res) => {
          return res.data as Letter[];
        })
      );
  }

  uploadAndSendLH(data: ScanLetter): Observable<LetterToHome> {
    const formData = new FormData();
    formData.append('image', data.file);
    formData.append('letter_id', data.letter.id.toString());
    formData.append('letter_no', data.letter.no);
    return this.http.post<any>(`${this.baseUrl}/letter/send/lh`, formData, {
      // reportProgress: true,
      // observe: "events",
      withCredentials: true,
    })
    .pipe(
      map((res) => {
        return res.data as LetterToHome;
      })
    );
  }

  messageToUser(data: OfficerMessageDto): Observable<OfficerMessage> {
    console.log(data);

    const formData = new FormData();
    if(data.file){
      formData.append('image', data.file);
    }
    formData.append('lp_id', data.lp_id.toString());
    formData.append('message', data.message);
    return this.http.post<any>(`${this.baseUrl}/officer/message/create`, formData, {
      // reportProgress: true,
      // observe: "events",
      withCredentials: true,
    })
    .pipe(
      map((res) => {
        return res.data as OfficerMessage;
      })
    );
  }
}
