
import { ScanSystemRouting } from './views/scan-system/scan-system.routing';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './helper/auth.guard';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { ForgetComponent } from './views/forget/forget.component';



export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'menu.login'
    }
  },
  {
    path: 'forget',
    component: ForgetComponent,
    data: {
      title: 'menu.forget'
    }
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: DefaultLayoutComponent,
    data: {
      title: 'menu.home'
    },

    children: [
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'sending',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/letter-to-home/letter-to-home.module').then(m => m.LetterToHomeModule)
      },
      {
        path: 'letter',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/letter/letter.module').then(m => m.LetterModule)
      },
      {
        path: 'letter-to-prison',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/letter-to-prison/letter-to-prison.module').then(m => m.LetterToPrisonModule)
      },
      {
        path: 'scaning',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/scan-system/scan-system.module').then(m => m.ScanSystemModule)
      }



    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    // NgxTranslateRoutesModule.forRoot({
    //   enableTitleTranslate: true,
    //   enableRouteTranslate: true
    // })
  ],
  exports: [ RouterModule,

    ],
})
export class AppRoutingModule {}
