import { ScanLetter } from './../../../model/scan-letter.model';

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'dialog-scan-img',
  templateUrl: 'dialog-scan-img.component.html',
})
export class DialogScanImageComponent {
  imgUrl: string;
  data: ScanLetter;

  constructor(
    private dialogRef: MatDialogRef<DialogScanImageComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.data = data;
  }

  onSubbmitManual(value: string) {
    this.data.no = value;
    this.dialogRef.close(this.data);
  }
}
