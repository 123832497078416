
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../service/authentication.service';
import { ReCaptchaService } from 'angular-recaptcha3';
import { MatDialog } from '@angular/material/dialog';
import { DialogSuccessComponent } from '../dialog/dialog-success/dialog-success.component';


@Component({
  selector: 'app-forget',
  templateUrl: 'forget.component.html'
})
export class ForgetComponent implements OnInit {
  isLoading: boolean;
  forgetForm: UntypedFormGroup;
  confirmForm: UntypedFormGroup;
  submitted = false;
  error = '';
  isSendOTP = false;



  constructor(private formBuilder: UntypedFormBuilder,
    private router: Router,
    public dialog: MatDialog,
    private translate: TranslateService,
    private authenticationService: AuthenticationService) {

    translate.use(translate.currentLang || translate.getDefaultLang());

  }
  public phoneValidator(event: any) {
    const pattern = /^[0-9]*$/;
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^0-9]/g, "");
    }
  }
  ngOnInit(): void {

    this.forgetForm = this.formBuilder.group({
      tel: ['',[
        Validators.required,
        Validators.pattern("^[0][0-9]+[0-9]$"),
        Validators.minLength(10), Validators.maxLength(10)]]
    });
    this.confirmForm = this.formBuilder.group({
      otp: ['', [
        Validators.required,
        Validators.pattern("^[0-9]*$"),
        Validators.minLength(5), Validators.maxLength(6)]],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    });

  }

  switchLanguage(): void {

    if (this.translate.currentLang == 'th') {
      this.translate.use('en');
    } else {
      this.translate.use('th');
    }

  }

  get f() { return this.forgetForm.controls; }

  onSubmitOTP() {


    // this.recaptchaService.execute({action: 'login'}).then(token => {
    //   // Backend verification method
    //   console.log(token);

    //   //this.sendTokenToBackend(token);
    // });


    // stop here if form is invalid
    if (this.forgetForm.invalid) {
      return;
    }

    this.isLoading = true;
    this.submitted = true;
    this.authenticationService.forget(this.f.tel.value)
      .subscribe({
        next: (res) => {
          this.isSendOTP = true;
          this.isLoading = false;
          this.dialog.open(DialogSuccessComponent, {
            data: {
              title : "success-title"
            },
            minHeight: '200px',
            minWidth: '300px',

          });

        },
        error: error => {
          console.log(error);

          this.error = error;
          this.isLoading = false;
        }
      });
  }

  onSubmitConfirm() {


    // this.recaptchaService.execute({action: 'login'}).then(token => {
    //   // Backend verification method
    //   console.log(token);

    //   //this.sendTokenToBackend(token);
    // });

    // stop here if form is invalid

    if (this.confirmForm.invalid) {
      return;
    }
    this.isLoading = true;
    this.submitted = true;
    this.authenticationService.forget(this.f.tel.value)
      .subscribe({
        next: (res) => {
          console.log(res);
          this.isSendOTP = true;
        },
        error: error => {
          this.error = error;
          this.isLoading = false;
        }
      });
  }


}

